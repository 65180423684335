

const Spiro = function(type){
    this.svg = document.createElement("img");
    this.svg.setAttribute('src', 'images/PICTO_EQUIPE ARTISTIQUE_DESSIN PAGE_Bleu.svg');
    /*
    this.svg.setAttribute('viewBox', '-150 -150 300 300');
    this.svg.setAttribute('width', '600');
    this.svg.setAttribute('height', '600');
    const g = document.createElementNS("http://www.w3.org/2000/svg",'g');
    this.svg.append(g);
    let count = 10;
    let angle = 0;
    for (let i = 0; i <= count; i++){
        var path = document.createElementNS("http://www.w3.org/2000/svg",'path');
        g.append(path);
        path.setAttribute('fill', "none");
        path.setAttribute('stroke', "#000000");
        path.setAttribute('d', "M-10,0 C-10,-20,20,-20,20,0 V100 C20, 120,-10, 120, -10, 100 Z");
        path.setAttribute('transform', "rotate(" + angle + ")");
        angle += 360 / count;
    }*/
    this.svg.style.position = 'fixed';
    this.svg.style.top = '20%';
    this.svg.style.left = '20%';
}


const Spirographe = function(){
    const sp = new Spiro();
    const sandbox = document.getElementById('spirographe');
    sandbox.append(sp.svg);
}

//document.addEventListener("DOMContentLoaded", function() { new Spirographe();});
